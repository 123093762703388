.navigation-bar-banner {
  width: 60%;
  position: relative;

  text-align: center;
  z-index: 1;
}

.navigation-bar-spacer {
  width: 100%;
  position: relative;
  display: block;
  z-index: 1;
}

.navigation-bar-container {
  width: 100%;
  position: fixed;
  border-bottom: 1px solid #e1e1e1;
  background-color: white;
  z-index: 1;
}

.navigation-bar-heading {
  display: inline-block;
}

.navigation-bar-warning {
  display: block;
}

.navigation-bar-toolbar {
  justify-content: space-between;
}

.navigation-bar-link {
  margin-left: 45px;
}

.navigation-bar-link-right {
  line-height: 32px;
  margin-right: 30px;
}

.navigation-bar-user {
  display: inline-block;
  margin: 7px 15px 7px 15px;
}

.navbar-distributor-dropdown {
  width: 250px;
  margin-right: 20px;
  font-size: 13px;
}

.navbar-user-info-container {
  display: inline-flex;
}

.copy-id-tooltip-white {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted white;
}

.copy-id-tooltip-white .copy-id-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-left: -50px;
  font-size: 11px;
}

.copy-id-tooltip-white:hover .copy-id-tooltiptext {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: #616161;
  opacity: 0.9;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -175px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}