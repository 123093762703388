.form-field-label {
  margin-top: 0px;
}

.form-field-textfield {
  width: 100%;
}

.form-heading-container {
  justify-content: space-between;
  display: flex;
  align-items: baseline;
}

.form-delete-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.form-delete-button {
  position: absolute;
  bottom: 0;
}

.form-number-input-container > * {
  width: 50px !important;
}

.form-number-input-container > * > input {
  min-width: 30px !important;
}
