.ims-org-textfield {
    margin-right: 10px;
    width: 300px;
}

.distributor-id-textfield {
    margin-right: 10px;
    width: 220px;
}

.success-color {
    color: green;
}

.new-line {
    white-space: pre;
}

.distributor-dropdown {
  width: 210px;
}

.clear-link {
    padding-left: 10px;
}
