.memberships-table-loading-spinner {
  left: -20px;
  top: 10px;
  margin-left: 50%;
  margin-bottom: 20px;
}

.memberships-heading-container {
  justify-content: space-between;
  display: flex;
  align-items: baseline;
}

.memberships-table-head {
  font-weight: 600 !important;
}

.rowC{
  display:flex;
  flex-direction:row;
  justify-content: right;
  align-items: center;
}

.sort-by-label {
  margin: 10px 10px 30px 0px;
}

.order-by-cell {
  width: 140px;
  margin: 10px 0px 30px 0px;

}

.sort-by-cell {
  width: 200px;
  margin: 10px 0px 30px 0px;

}

.page-number-cell {
  width: 75px;
  margin: 10px 0px 30px 0px;

}
