.country-table-loading-spinner {
    left: -20px;
    top: 10px;
    margin-left: 50%;
    margin-bottom: 20px;
  }
  
  .country-heading-container {
    width: '100%';
    justify-content: space-between;
    display: flex;
    align-items: baseline;
  }
  
  .country-table-head {
    width: '100%';
    font-weight: 600 !important;
  }
  