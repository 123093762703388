.ims-org-textfield {
    margin-right: 10px;
    width: 300px;
}

.distributor-id-textfield {
    margin-right: 10px;
    width: 275px;
}

.success-color {
    color: green;
}

.new-line {
    white-space: pre;
}

.distributor-dropdown {
  width: 210px;
}

.clear-link {
    padding-left: 10px;
}

.progress-text-positions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.spectrum-Accordion-itemHeader {
    font-size: 14px;
    font-weight: bold;
}

.contact-table-loading-spinner {
    left: -20px;
    top: 10px;
    margin-left: 50%;
    margin-bottom: 20px;
  }
  
  .contact-heading-container {
    justify-content: space-between;
    display: flex;
    align-items: baseline;
  }
  
  .contact-table-head {
    font-weight: 600 !important;
  }

  .contact-table {
      max-height: 200px;
  }
  