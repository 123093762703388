.copy-id-tooltip {
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .copy-id-tooltip .copy-id-tooltiptext {
    visibility: hidden;
    width: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-left: -50px;
    font-size: 11px;
  }
  
  .copy-id-tooltip:hover .copy-id-tooltiptext {
    visibility: visible;
  }
  
  .resource-status-success {
    border: 1px solid #4a7227;
    background-color: #d0e9ba;
    border-radius: 4px;
    color: #4a7227;
  }
  
  .resource-status-pending {
    border: 1px solid #a88b22;
    background-color: #f5e4a9;
    border-radius: 4px;
    color: #a88b22;
  }
  
  .resource-status-failure {
    border: 1px solid #9b3a3a;
    background-color: #fad7d7;
    border-radius: 4px;
    color: #9b3a3a;
  }
  