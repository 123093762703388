.select-field {
    padding: 1px 0px 1px 0px;
  }
  
  .table {
    display: table;
    width: 100%;
  }
  
  .table-header {
    display: table-header-group;
    font-weight: bold;
    font-size: 12px;
  }
  
  .table-row {
    display: flex;
    flex-flow: wrap;
  }
  
  .table-body {
    display: table-row-group;
  }
  
  .table-cell {
    width: 29%;
    padding: 2px;
  }
  
  .table-small-cell {
    width: 45px;
  }
  
  .colspan4 {
    flex: 4;
  }

  .offer-details-tooltip {
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    margin-left: 5px; /* or whatever */

  }
  
  .offer-details-tooltip .offer-details-tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-left: -50px;
    font-size: 11px;
  }
  
  .offer-details-tooltip:hover .offer-details-tooltiptext {
    visibility: visible;
  }