.App {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.App-content {
  padding: 100px 100px 0px 100px;
}

.App-footer {
  height: 150px;
}
