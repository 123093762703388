.access-denied-logout {
  position: absolute;
  top: 30px;
  right: 30px;
}

.access-denied-site-title {
  position: absolute;
  top: 30px;
  left: 30px;
}

.access-denied-warning {
  height: 100vh;
}
