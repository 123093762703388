.subresources-table-head-icon {
  vertical-align: bottom;
  transform: scale(0.75);
}

.subresources-table-head {
  font-weight: 600 !important;
}

.subresources-status-success {
  border: 1px solid #4a7227;
  background-color: #d0e9ba;
  border-radius: 4px;
  color: #4a7227;
}

.subresources-status-pending {
  border: 1px solid #a88b22;
  background-color: #f5e4a9;
  border-radius: 4px;
  color: #a88b22;
}

.subresources-status-failure {
  border: 1px solid #9b3a3a;
  background-color: #fad7d7;
  border-radius: 4px;
  color: #9b3a3a;
}
