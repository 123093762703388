.offers-table-loading-spinner {
    left: -20px;
    top: 10px;
    margin-left: 50%;
    margin-bottom: 20px;
}

.offers-heading-container {
    justify-content: space-between;
    display: flex;
    align-items: baseline;
}

.offers-table-head {
    font-weight: 600 !important;
}