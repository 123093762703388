.table-loading-spinner {
  left: -20px;
  top: 10px;
  margin-left: 50%;
  margin-bottom: 20px;
}

.table-head-icon {
  vertical-align: bottom;
  transform: scale(0.75);
}

.records-table-head {
  font-weight: 600 !important;
  text-align: center !important;
}

.records-table-body {
  text-align: center !important;
}