.memberships-table-loading-spinner {
  left: -20px;
  top: 10px;
  margin-left: 50%;
  margin-bottom: 20px;
}

.memberships-header-container {
  justify-content: space-between;
  display: flex;
  align-items: baseline;
}

.memberships-table-head {
  font-weight: 600 !important;
}
