.add-emails-textfield {
  height: 100px;
  width: 100%;
}

.distributor-select-heading-container {
  justify-content: space-between;
  display: flex;
  align-items: baseline;
}

.distributor-select-container {
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: space-between;
}

.distributor-select-menu {
  width: 100%;
}

.distributor-select-list {
  background-color: rgb(255, 255, 255);
  border-color: rgb(225, 225, 225);
  color: rgb(75, 75, 75);
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  width: 100%;
}

.copy-icon {
  /* font-size: 0.04em; */
  width: 3px;
  height: 3px;

}

.distirbutor-data {
  align-items: center;
  text-align: center;
}

.distributor-item {
  padding-left: 10px;
  padding-right: 20px;
}

.tiny-icon {
  font-size: 1rem;
}