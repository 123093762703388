.expand-detail {
  font-style: italic;
  margin: 0px;
}

.row-container {
  display: inline-block;
}

.table-row {
  display: flex;
  flex-flow: wrap;
}

.table-cell {
  width: 29%;
  padding: 2px;
}

.table-small-cell {
  width: 35px;
}

.detail-table-cell {
  padding: 1px 8px 1px 8px;
  font-size: 13px;
}

.view-button {
  display: flex;
  align-items: center;
  width: 480px;
  justify-content: space-between;
}

.spectrum-ActionButton-label {
  text-overflow: initial;
  white-space: pre-wrap;
}

