.quick-create-heading-container {
  justify-content: space-between;
  display: flex;
  align-items: baseline;
}

.quick-crete-field-label {
  margin-top: 0px;
}

.quick-create-number-input-container > * {
  width: 50px !important;
}

.quick-create-number-input-container > * > input {
  min-width: 30px !important;
}

.quick-create-textfield {
  width: 100%;
}

.quick-create-radio {
  width: 200px;
}
