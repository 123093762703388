.records-tab-container {
  margin-top: 15px;
}

.search-container {
  position: relative;
  width: 100%;
  margin: 10px 0px 30px 0px;
}

.loading-spinner {
  position: fixed;
  left: 50%;
}

.no-data-label {
  margin-left: 30px;
}

.records-search-bar {
  width: 300px;
}

.records-search-bar > input {
  width: 300px !important;
}

.rowC{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
}

.filter-by-cell {
  width: 140px;
  margin: 10px 0px 30px 0px;

}

.order-by-cell {
  width: 120px;
  margin: 10px 0px 30px 0px;

}

.additional-sort-label {
  /* width: 40; */
  margin: 10px 0px 30px -30px;

}

.sort-by-cell {
  width: 140px;
  margin: 10px 0px 30px 0px;

}

.page-number-cell {
  width: 75px;
  margin: 10px 0px 30px 0px;

}

.order-id-textfield {
  margin-right: 10px;
  width: 220px;
}

